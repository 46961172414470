// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Textfit } from 'react-textfit'
import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <div>
          <div>
            <h1>Sri Aurobindo's Savitri</h1>
            <h2>The Auroville Radio Talks</h2>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.img)}
              objectPosition="center top"
            />
          </div>
          <div>
            <div>
              <p>
                “Ambassadress twixt eternity and change,
                <br />
                The omniscient Goddess leaned across the breadths <br />
                That wrap the fated journeying of the stars
                <br />
                And saw the spaces ready for her feet.
                <br />
                Once she half looked behind for her veiled sun,
                <br />
                Then, thoughtful, went to her immortal work.
                <br />
                Earth felt the Imperishable’s passage close:
                <br />
                The waking ear of Nature heard her steps
                <br />
                And wideness turned to her its limitless eye,
                <br />
                And, scattered on sealed depths, her luminous smile
                <br />
                Kindled to fire the silence of theworlds.
                <br />
                All grew a consecration and a rite.”
              </p>
              <p>Sri Aurobindo, Savitri</p>
            </div>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <div>
            <p className="dropcap">
              <strong style={{ fontWeight: 600 }}>
                <em>
                  <span>S</span>ri Aurobindo’s Savitri: The Auroville Radio
                  Talks
                </em>{' '}
                is a four volume work that emerges from talks recorded by
                Loretta Shartsis for Auroville Radio over the last seven years.
              </strong>
            </p>
            <p className="text-indent">
              Sri Aurobindo’s epic poem <em>Savitri</em> brings us realisations
              and consciousness. The underlying story of Savitri comes from the
              Mahabharata, an Ancient Indian epic. Savitri is a young woman who
              rescues her husband from Death. Within and around this simple
              tale, Sri Aurobindo wrote many stories, and stories within
              stories, all of which reveal eternal Truths. He brings to us - and
              brings us to - all the levels of being that we are and that we
              live in. He used mystic poetry in order to communicate the deeper
              and higher levels of being. He said that he wrote his own
              experiences and realisations in Savitri. His purpose was to fill
              the book with the living consciousness that he attained; not to
              finish it, but to keep rewriting it with every new realisation and
              achievement, and to communicate everything to the reader.
            </p>
            <TableOfContents lang={lang} />
          </div>
          <div>&nbsp;</div>
        </div>
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
